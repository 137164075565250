import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function GiveCertainPlayersSlokken(props) {
  var playersRef = firebase.firestore().collection("/players");

  const [players, setPlayers] = useState([]);
  const [slokkenAmount, setSlokkenAmount] = useState(15);
  const [disable, setDisable] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();

    if (players.length > 0) {
      console.log(players);
      players.forEach((player) => {
        console.log(slokkenAmount);
        console.log(player.slokkenTotal);
        playersRef.doc(props.gameId + "_" + player.id).update({
          slokkenTotal: parseInt(
            parseInt(player.slokkenTotal) + parseInt(slokkenAmount)
          ),
        });
      });

      setSuccess(true);
      setDisable(true);
    } else {
      console.log("geen spelers gekozen");
    }
  };

  const addUser = (id, naam, slokkenTotal) => {
    var found = false;
    for (var i = 0; i < players.length; i++) {
      if (players[i].id === id) {
        found = true;
        break;
      }
    }

    if (found) {
      setPlayers(players.filter((item) => item.id !== id));
    } else {
      players.push({ id: id, naam: naam, slokkenTotal: slokkenTotal });
      console.log(players);
    }
  };

  return (
    <Form onSubmit={sendMessage}>
      <br />
      {success && (
        <Alert variant="success">
          De geselecteerde hebben {slokkenAmount} slokken er bij gekregen.
        </Alert>
      )}

      <fieldset disabled={disable}>
        <Form.Group controlId="formBasicCheckbox">
          {props.players != null ? (
            props.players.map((player) => (
              <Form.Check
                type="checkbox"
                key={player.userId}
                label={player.playerName}
                onChange={() =>
                  addUser(player.userId, player.playerName, player.slokkenTotal)
                }
              />
            ))
          ) : (
            <p>Er zijn geen spelers om toe te voegen.</p>
          )}
        </Form.Group>

        <Form.Label>Aantal slokken</Form.Label>
        <input
          type="number"
          min="1"
          max="150"
          name="amountOfSlokken"
          autoComplete="off"
          className="form-control"
          value={slokkenAmount}
          onChange={(e) => setSlokkenAmount(e.target.value)}
        ></input>
        <br />

        <Button variant="primary" type="submit">
          Geef slokken
        </Button>
      </fieldset>
    </Form>
  );
}
