import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function NewGame() {
  var usersRef = firebase.firestore().collection("/users");
  const query = usersRef.orderBy("naam", "asc");

  const [locatie, setLocatie] = useState("");
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState("");

  const [userName, setUserName] = useState("");
  const [hideUserCreation, setHideUserCreation] = useState(true);
  const [successCreation, setSuccessCreation] = useState(false);

  const [users] = useCollectionData(query, {
    idField: "id",
  });

  var gamesRef = firebase.firestore().collection("/games");
  var playersRef = firebase.firestore().collection("/players");

  const sendMessage = async (e) => {
    e.preventDefault();

    if (locatie.length > 0) {
      if (players.length > 1) {
        setError("");
        console.log(locatie);
        console.log(players);

        await gamesRef
          .add({
            dobbelLimit: 15,
            date: firebase.firestore.FieldValue.serverTimestamp(),
            location: locatie,
          })
          .then(function (gameDoc) {
            players.forEach((player) => {
              console.log("Nieuwe inserten! " + player.naam);
              playersRef.doc(gameDoc.id + "_" + player.id).set({
                gameId: gameDoc.id,
                userId: player.id,
                playerName: player.naam,
                slokkenDone: 0,
                slokkenTotal: 0,
                streetAmount: 0,
                warnings: 0,
              });
            });
            window.location.replace("/specificGame/" + gameDoc.id);
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      } else {
        setError("Er moeten meerdere spelers mee doen..");
      }
    } else {
      setError("De locatie moet ingevuld zijn...");
    }
  };

  const createUser = async (e) => {
    e.preventDefault();
    usersRef.add({
      naam: userName,
    });
    console.log(userName);
    setSuccessCreation(true);
    setUserName("");
  };

  const addUser = (id, naam) => {
    var found = false;
    for (var i = 0; i < players.length; i++) {
      if (players[i].id === id) {
        found = true;
        break;
      }
    }

    if (found) {
      setPlayers(players.filter((item) => item.id !== id));
    } else {
      players.push({ id: id, naam: naam });
      console.log(players);
    }
  };

  return (
    <div>
      <Button
        variant="secondary"
        onClick={() => setHideUserCreation(!hideUserCreation)}
      >
        Nieuwe gebruiker aanmaken?
      </Button>
      <br />
      <br />
      {hideUserCreation === false && (
        <Form onSubmit={createUser}>
          {successCreation && (
            <Alert variant="success">
              Speler is aangemaakt(en toegevoegd aan de onderstaande lijst)
            </Alert>
          )}
          <Form.Group controlId="formBasicUser">
            <Form.Label>Nieuwe speler aanmaken(optioneel)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Spelernaam"
              required
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>
          <Button variant="outline-primary" type="submit">
            Speler aanmaken
          </Button>
          <br />
          <br />
        </Form>
      )}
      <Form onSubmit={sendMessage}>
        {error.length > 0 ? <Alert variant="danger">{error}</Alert> : ""}
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Locatie</Form.Label>
          <Form.Control
            type="text"
            placeholder="Locatie"
            required
            value={locatie}
            onChange={(e) => setLocatie(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicCheckbox">
          {users != null ? (
            users.map((user) => (
              <Form.Check
                type="checkbox"
                key={user.id}
                label={user.naam}
                onChange={() => addUser(user.id, user.naam)}
              />
            ))
          ) : (
            <p>Er zijn geen spelers om toe te voegen.</p>
          )}
        </Form.Group>

        <Button variant="primary" type="submit">
          Sessie starten
        </Button>
      </Form>
    </div>
  );
}
