import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Player from "./Player";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { useDocument } from "react-firebase-hooks/firestore";
import AddPlayerToGame from "./AddPlayerToGame";
import GiveCertainPlayersSlokken from "./GiveCertainPlayersSlokken";

export default function SpecificGame(props) {
  const gameId = props.match.params.id;
  const [showAddPlayer, setShowAddPlayers] = useState(false);
  const [showGiveXSlokken, setShowGiveXSlokken] = useState(false);

  var notulistMode = false;

  if (sessionStorage.getItem("password") === "notulist") {
    notulistMode = true;
  }

  var playersRef = firebase.firestore().collection("/players");

  const queryPlayers = playersRef.where("gameId", "==", gameId);

  const [players] = useCollectionData(queryPlayers, { idField: "id" });

  const [value, loading, error] = useDocument(
    firebase.firestore().doc("games/" + gameId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const increaseDobbelLimit = () => {
    console.log("increasing dobbel limit");
    firebase
      .firestore()
      .collection("games")
      .doc(gameId)
      .update({ dobbelLimit: value.data().dobbelLimit + 5 });
  };

  return (
    <div className="specificGame">
      <Nav
        defaultActiveKey="/home"
        as="ul"
        className="fixed-bottom justify-content-center bg-white "
        fill
      >
        {players != null &&
          players.map((player) => {
            return (
              <Nav.Item as="li" key={player.playerName}>
                <Nav.Link href={"#" + player.userId}
                  className={
                    value != null ?
                      ((player.slokkenTotal - player.slokkenDone) > parseInt(value.data().dobbelLimit) ? "color-red" : "nav-link")
                      : "nav-link"}>
                  {player.playerName}
                </Nav.Link>
              </Nav.Item>
            );
          })}
      </Nav>

      <h1>Dertigen overzicht</h1>

      {notulistMode && (
        <Button
          variant="outline-secondary"
          className="no-side-padding"
          onClick={() => setShowAddPlayers(!showAddPlayer)}
        >
          Spelers toevoegen
        </Button>
      )}
      {showAddPlayer && <AddPlayerToGame gameId={gameId} />}
      <br />
      <br />
      {notulistMode && (
        <Button
          variant="outline-secondary"
          className="no-side-padding"
          onClick={() => setShowGiveXSlokken(!showGiveXSlokken)}
        >
          Geef bepaalde mensen slokken
        </Button>
      )}
      {showGiveXSlokken && (
        <GiveCertainPlayersSlokken gameId={gameId} players={players} />
      )}
      <br />
      <br />

      <Row>
        <Col>
          <h5>Dobbellimiet: {value != null ? value.data().dobbelLimit : ""}</h5>
        </Col>
        <Col>
          {notulistMode && (
            <Button variant="link no-top-padding" onClick={increaseDobbelLimit}>
              Verhogen
            </Button>
          )}
        </Col>
      </Row>
      <br />
      {players != null ? (
        players.map((player) => (
          <div id={player.userId} key={player.userId}>
            <Player
              gameId={gameId}
              userId={player.userId}
              playerName={player.playerName}
              slokkenDone={player.slokkenDone}
              slokkenTotal={player.slokkenTotal}
              notulistMode={notulistMode}
              streetAmount={player.streetAmount}
              warnings={player.warnings}
              dobbelLimit={value != null ? value.data().dobbelLimit : 15}
            ></Player>
          </div>
        ))
      ) : (
        <p>Er zijn geen spelers in deze game...</p>
      )}
    </div>
  );
}
