import React, { useState } from "react";
import firebase from "firebase/app";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function Player(props) {
  const [slokkenInput, setSlokkenInput] = useState("1");

  var notulistMode = props.notulistMode;

  function DrawStripes(imgName, amount) {
    var stripes = [];

    for (let i = 0; i < amount; i = i) {
      if (amount - 5 >= 0) {
        amount = amount - 5;
        stripes.push(5);
      } else if (amount - 4 >= 0) {
        amount = amount - 4;
        stripes.push(4);
      } else if (amount - 3 >= 0) {
        amount = amount - 3;
        stripes.push(3);
      } else if (amount - 2 >= 0) {
        amount = amount - 2;
        stripes.push(2);
      } else if (amount - 1 >= 0) {
        amount = amount - 1;
        stripes.push(1);
      } else {
        break;
      }
    }

    return stripes.map((stripe, key) => {
      return (
        <img
          key={key}
          className="slokken"
          src={`/img/${imgName}${stripe}.png`}
          alt="Slokken"
        />
      );
    });
  }

  function addSlokken(amount) {
    firebase
      .firestore()
      .collection("players")
      .doc(props.gameId + "_" + props.userId)
      .update({ slokkenTotal: props.slokkenTotal + amount });
  }

  function addSlokkenViaInput(e) {
    e.preventDefault();
    var slokkenInputInt = Number(slokkenInput);
    firebase
      .firestore()
      .collection("players")
      .doc(props.gameId + "_" + props.userId)
      .update({ slokkenTotal: props.slokkenTotal + slokkenInputInt });
  }

  function addSlokkenDone(amount) {
    var tempSlokkenDone = props.slokkenDone + amount;
    var checkSlokken = props.slokkenTotal - tempSlokkenDone;
    if (checkSlokken >= 0) {
      firebase
        .firestore()
        .collection("players")
        .doc(props.gameId + "_" + props.userId)
        .update({ slokkenDone: props.slokkenDone + amount });
    } else {
      console.log("Slokken zijn al gelijk");
    }
  }

  function addStreet(userId) {
    firebase
      .firestore()
      .collection("players")
      .doc(props.gameId + "_" + userId)
      .update({ streetAmount: props.streetAmount + 1 });
  }

  function addWarning(userId) {
    firebase
      .firestore()
      .collection("players")
      .doc(props.gameId + "_" + userId)
      .update({ warnings: props.warnings + 1 });
  }

  return (
    <div className="player">
      <h3 className={(props.slokkenTotal - props.slokkenDone) > parseInt(props.dobbelLimit) ? "playerName color-red" : "playerName"}>
        Speler: {props.playerName != null ? props.playerName : ""}{" "}
      </h3>
      <p>
        <Row>
          <Col xs={5}>Waarschuwingen: </Col>
          <Col>
            <strong>
              {props.warnings}{" "}
              {notulistMode && (
                <Button
                  variant="link"
                  size="sm"
                  className="add-street"
                  onClick={() => addWarning(props.userId)}
                >
                  + waarschuwing geven
                </Button>
              )}
            </strong>
          </Col>
        </Row>
        <Row>
          <Col xs={5}>Straten: </Col>
          <Col>
            <strong>
              {props.streetAmount}{" "}
              {notulistMode && (
                <Button
                  variant="link"
                  size="sm"
                  className="add-street"
                  onClick={() => addStreet(props.userId)}
                >
                  + straat toevoegen
                </Button>
              )}
            </strong>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={5}>Slokken totaal: </Col>
          <Col>
            <strong>{props.slokkenTotal}</strong>
          </Col>
        </Row>
        <Row>
          <Col xs={5}>Slokken gedaan: </Col>
          <Col>
            <strong>
              {props.slokkenDone} ({Math.floor(props.slokkenDone / 10)} adjes)
            </strong>
          </Col>
        </Row>
        <Row>
          <Col xs={5}>Slokken over: </Col>
          <Col>
            <strong className={(props.slokkenTotal - props.slokkenDone) > parseInt(props.dobbelLimit) ? "color-red" : ""}>
              {props.slokkenTotal - props.slokkenDone} / {props.dobbelLimit}
            </strong>
          </Col>
        </Row>
      </p>
      <p>
        <strong>
          {props.slokkenDone > 0 &&
            DrawStripes("slokkenDone-", props.slokkenDone)}
        </strong>{" "}
        {props.slokkenTotal > 0 &&
          DrawStripes("slokken-", props.slokkenTotal - props.slokkenDone)}
      </p>
      <br />
      {
        notulistMode && (
          <div>
            <Row>
              <Col>
                <Button
                  variant="outline-primary bold-text"
                  onClick={() => addSlokken(10)}
                >
                  +10 slokken
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-primary bold-text"
                  onClick={() => addSlokken(5)}
                >
                  +5 slokken
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              {/* </Row>
      <br />
      <Row className="align-middle"> */}
              <Form onSubmit={(e) => addSlokkenViaInput(e)}>
                <Col>
                  <input
                    type="number"
                    min="1"
                    max="150"
                    name="amountOfSlokken"
                    autoComplete="off"
                    className="input_slokken"
                    value={slokkenInput}
                    onChange={(e) => setSlokkenInput(e.target.value)}
                  ></input>
                  <Button variant="outline-primary bold-text" type="submit">
                    Toevoegen
                  </Button>
                </Col>
              </Form>
            </Row>
            <br></br>
            <Row>
              <Col>
                <Button
                  variant="outline-success bold-text"
                  onClick={() => addSlokkenDone(10)}
                >
                  -10 slokken
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-secondary bold-text"
                  onClick={() => addSlokkenDone(5)}
                >
                  -5 slokken
                </Button>
              </Col>
            </Row>
          </div>
        )
      }
    </div >
  );
}
