import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function AddPlayerToGame(props) {
  var usersRef = firebase.firestore().collection("/users");
  const query = usersRef.orderBy("naam", "asc");

  var playersRef = firebase.firestore().collection("/players");
  const [players, setPlayers] = useState([]);

  const [users] = useCollectionData(query, {
    idField: "id",
  });

  const sendMessage = async (e) => {
    e.preventDefault();
    if (players.length > 0) {
      console.log(players);
      players.forEach((player) => {
        console.log("Nieuwe inserten! " + player.naam);

        playersRef
          .doc(props.gameId + "_" + player.id)
          .get()
          .then((docSnapshot) => {
            if (docSnapshot.exists) {
              console.log("speler doet al mee");
            } else {
              playersRef.doc(props.gameId + "_" + player.id).set({
                gameId: props.gameId,
                userId: player.id,
                playerName: player.naam,
                slokkenDone: 0,
                slokkenTotal: 0,
                streetAmount: 0,
                warnings: 0,
              });
            }
          });
      });
    } else {
      console.log("geen spelers gekozen");
    }
  };

  const addUser = (id, naam) => {
    var found = false;
    for (var i = 0; i < players.length; i++) {
      if (players[i].id === id) {
        found = true;
        break;
      }
    }

    if (found) {
      setPlayers(players.filter((item) => item.id !== id));
    } else {
      players.push({ id: id, naam: naam });
      console.log(players);
    }
  };

  return (
    <Form onSubmit={sendMessage}>
      <Form.Group controlId="formBasicCheckbox">
        {users != null ? (
          users.map((user) => (
            <Form.Check
              type="checkbox"
              key={user.id}
              label={user.naam}
              onChange={() => addUser(user.id, user.naam)}
            />
          ))
        ) : (
          <p>Er zijn geen spelers om toe te voegen.</p>
        )}
      </Form.Group>

      <Button variant="primary" type="submit">
        Toevoegen aan sessie
      </Button>
    </Form>
  );
}
