import React from "react";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Games() {
  var gamesRef = firebase.firestore().collection("/games");
  const query = gamesRef.orderBy("date", "desc");

  const [games] = useCollectionData(query, {
    idField: "id",
  });

  var notulistMode = false;

  if (sessionStorage.getItem("password") === "notulist") {
    notulistMode = true;
  }

  return (
    <Container>
      <h1>Overzicht</h1>
      {notulistMode && <Link to="/newGame">Nieuwe sessie aanmaken</Link>}
      <br />
      <br />
      {games != null ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Locatie</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {games.map((game) => (
              <tr key={game.id}>
                <td>
                  {game.date != null &&
                    new Date(game.date.seconds * 1000).toLocaleDateString()}
                </td>
                <td>{game.location}</td>
                <td>
                  <Link to={{ pathname: `/specificGame/${game.id}` }}>
                    Bekijken{" "}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Er zijn nog geen games aangemaakt</p>
      )}
    </Container>
  );
}
