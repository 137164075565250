import React, { Component } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = { password: "", incorrect: false };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (
      this.state.password === "pilsislife" ||
      this.state.password === "Pilsislife"
    ) {
      sessionStorage.setItem("password", "123");
      window.location.reload();
    } else if (this.state.password === "notulistgang") {
      sessionStorage.setItem("password", "notulist");
      window.location.reload();
    } else {
      this.setState({ incorrect: true });
    }
  };

  handleChange = (event) => {
    this.setState({ password: event.target.value });
  };

  render() {
    return (
      <Container className="signin">
        {this.state.incorrect && (
          <Alert variant="danger">Wachtwoord incorrect!</Alert>
        )}

        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <p>Voer het wachtwoord in</p>
          <input
            placeholder="Is pils lekker?"
            type="text"
            name="password"
            autoComplete="off"
            value={this.state.password}
            onChange={this.handleChange}
          ></input>
          <br />
          <br />
          <Button variant="primary" type="submit" password>
            Inloggen
          </Button>
        </Form>
      </Container>
    );
  }
}
