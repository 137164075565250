import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import SignIn from "./SignIn";
import Games from "./Games";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import Navigation from "./Navigation";
import SpecificGame from "./SpecificGame";
import NewGame from "./NewGame";
import LeaderBoards from "./LeaderBoards";

firebase.initializeApp({
  apiKey: "AIzaSyDVV2DTgB_Qjzw7977Gvl_60PBUlOaUKfY",
  authDomain: "dertigen-2afac.firebaseapp.com",
  databaseURL: "https://dertigen-2afac.firebaseio.com",
  projectId: "dertigen-2afac",
  storageBucket: "dertigen-2afac.appspot.com",
  messagingSenderId: "1039805934561",
  appId: "1:1039805934561:web:db9a7dc3ac04ae04fb80a0",
});

function App() {
  return (
    <Router>
      <div>
        {sessionStorage.getItem("password") === "123" ||
        sessionStorage.getItem("password") === "notulist" ? (
          <Navigation>
            <Switch>
              <Route path="/specificGame/:id" component={SpecificGame} />
              <Route path="/newGame" component={NewGame} />
              <Route path="/leaderboards" component={LeaderBoards} />
              <Route exact path="/" component={Games} />
            </Switch>
          </Navigation>
        ) : (
          //return password component
          <SignIn></SignIn>
        )}
      </div>
    </Router>
  );
}

export default App;
