// import React, { useEffect, useState } from "react";
import React, { Component } from "react";
import firebase from "firebase/app";

// export default function LeaderBoards() {
//   const [statsState, setStatsState] = useState([{}]);

//   getData();

//   console.log(stats);

//   return (
//     <div>
//       {stats != null
//         ? stats.map((stat, key) => <p key={key}>{stat.playerName}</p>)
//         : "Geen statistieken om te weergeven"}

//       {stats != null && JSON.stringify(stats)}
//     </div>
//   );
// }

export default class LeaderBoards extends Component {
  constructor(props) {
    super(props);
    this.state = { stats: [] };
  }
  getData() {
    var playersRef = firebase.firestore().collection("/players");
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((userDoc) => {
          playersRef
            .where("userId", "==", userDoc.id)
            .get()
            .then((playerSnapshot) => {
              //voor alle users de playdata ophalen
              playerSnapshot.forEach((playerDoc) => {
                var objectFound = this.state.stats.find((obj) => {
                  return obj.userId === playerDoc.data().userId;
                });

                //checken of userid al bestaat in data
                var index = this.state.stats.indexOf(objectFound);

                if (index >= 0) {
                  console.log("Userid bestaat al, data samenvoegen");

                  const newStats = this.state.stats.slice(); //copy the array

                  newStats[index].slokkenDone =
                    newStats[index].slokkenDone + playerDoc.data().slokkenDone;

                  newStats[index].slokkenTotal =
                    newStats[index].slokkenTotal +
                    playerDoc.data().slokkenTotal;

                  this.setState({ stats: newStats }); //set the new state
                } else {
                  //user bestaat nog niet, toevoegen aan array
                  this.setState({
                    stats: [
                      ...this.state.stats,
                      {
                        userId: playerDoc.data().userId,
                        playerName: userDoc.data().naam,
                        slokkenTotal: playerDoc.data().slokkenTotal,
                        slokkenDone: playerDoc.data().slokkenDone,
                      },
                    ],
                  });
                }
              });

              //array sorten
              const statsSorted = this.state.stats.slice();

              statsSorted.sort(
                (a, b) => parseFloat(b.slokkenDone) - parseFloat(a.slokkenDone)
              );

              this.setState({ stats: statsSorted });

              console.log(this.state.stats);
            });
        });
      });
  }
  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div>
        <h1>Leaderboards</h1>
        {this.state.stats != null
          ? this.state.stats.map((stat, key) => (
              <div>
                {key === 0 && <h1 className="leaderboard-1">#1</h1>}
                {key === 1 && <h2 className="leaderboard-2">#2</h2>}
                {key === 2 && <h3 className="leaderboard-3">#3</h3>}
                <p key={key}>
                  <h4>
                    {stat.playerName}
                    {key >= 3 && "(" + (key + 1) + ")"}
                  </h4>
                  <u>Totaal aantal gedronken:</u>{" "}
                  <strong>
                    {stat.slokkenDone} slokken (
                    {Math.floor(stat.slokkenDone / 10)} adjes)
                  </strong>
                  <br />
                  <u>Totaal aantal slokken:</u> {stat.slokkenTotal} slokken
                </p>
                <br />
              </div>
            ))
          : ""}
      </div>
    );
  }
}
